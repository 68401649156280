import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import close from '../Assets/close.svg';
import 'react-quill/dist/quill.snow.css';

const fonts = {
  'serif': 'serif',
  'Helvetica': 'Helvetica',
  'monospace': 'monospace',
  'Comic Sans MS': 'Comic_Sans_MS',
};

const Font = Quill.import('formats/font');
Font.whitelist = Object.values(fonts);
Quill.register(Font, true);

const EmailPopup = ({ onClose, onSend, responseMessage, errorMessage, setEmailSubject, setEmailBody, emailSubject, emailBody }) => {

  const modules = {
    toolbar: [
      [{ 'font': Object.keys(fonts) }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['clean'],
    ],
  };

  const formats = [
    'font',
    'bold', 'italic', 'underline',
    'list', 'bullet',
  ];

  const stripHtml = (html) => {
    const temporalDivElement = document.createElement('div');
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  };  

  const handleSend = (e) => {
    e.preventDefault();
    const plainTextBody = stripHtml(emailBody);
    console.log("After stripping HTML:", plainTextBody);
  
    if (!emailSubject.trim() || !plainTextBody.trim()) {
      alert('Please provide both subject and message body.');
      return;
    }
  
    onSend(emailSubject, plainTextBody);
  };  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full text-gray-700">
      <div className='flex flex-row justify-between mb-6'>
          <label htmlFor="messageText" style={{ fontSize: '24px', fontWeight: 'bold' }}>Send Email</label>
          <img
            onClick={onClose}
            src={close}
            alt="User"
            className="cursor-pointer"
          />
        </div>

        {responseMessage && 
          <div className="bg-green-200 w-full text-green-800 p-2 mb-4">
            {responseMessage}
          </div>
        }

        {errorMessage && 
          <div className="bg-red-200 w-full text-red-800 p-2 mb-4">
            {errorMessage}
          </div>
        }

        <label className="block text-gray-700">Subject</label>
        <input
            type="text"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
            className="w-full border border-gray-300 p-2 mb-4"
            placeholder="Enter subject"
        />
        
        <label className="block text-gray-700">Email Body</label>
        <ReactQuill
            theme="snow"
            value={emailBody}
            onChange={setEmailBody}
            modules={modules}
            formats={formats}
            bounds={'.app'}
            placeholder="Compose your email..."
        />

        <div className="flex justify-end gap-4 mt-4">
          <button onClick={onClose} className="bg-gray-400 text-white px-4 py-2 rounded">
            Close
          </button>
          <button onClick={(e) => handleSend(e)} className="bg-blue-500 text-white px-4 py-2 rounded">
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailPopup;
