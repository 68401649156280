import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import close from '../Assets/close.svg';

const EControlUnitForm = ({ closeModal, editMode, deviceData, updateTableData, onConnectionUpdated }) => {

  const [updateStatus, setUpdateStatus] = useState(null);
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [companies, setCompanies] = useState([{ company: 'None', CompanyID: null }]);
  const [settings, setSettings] = useState([{ settingId: null, settingName: 'Default Settings' }]);
  const [Connections, setConnections] = useState([{ Connection: 'None', Connection_ID: null }]);
  const categories = ['None', 'Control', 'Input', 'OutPut'];
  const [formErrors, setFormErrors] = useState({});
  const [gradientHeight, setGradientHeight] = useState('');
  
  useEffect(() => {
    const updateGradientHeight = () => {
      const height = window.innerWidth < 640 ? '13.7%' : '17.5%';
      setGradientHeight(height);
    };
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, []);

  const [formData, setFormData] = useState({
    unitId: "",
    macAddress: "",
    hardwareDetails: "",
    category: "",
    description: "",
    venue: "",
    entryway: "",
    company: "",
    Connection:"",
    Settings:"",
    settingId: null
  });

  const validateForm = () => {
    const errors = {};
    if (!formData.macAddress?.trim()) errors.macAddress = 'Mac Address is required';
    if(!formData.hardwareDetails) {
      errors.hardwareDetails = 'Hardware Details is required'
    }
    if (!formData.category) {
      errors.category = 'Category is required'
    }
    if (!formData.description) {
      errors.description = 'Description is required'
    }
    if (!formData.venue) {
      errors.venue = 'Venue is required'
    }
    if (!formData.entryway) {
      errors.entryway = 'Entryway is required'
    }
    if (!formData.company) {
      errors.company = 'Company is required'
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch('https://eunitstest.onrender.com/api/getcompanydetails');
        if (response.ok) {
          const data = await response.json();
          setCompanies([{ company: 'None', CompanyID: null }, ...data]);
        } else {
          console.error('Failed to fetch companies. HTTP status:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchConnections = async () => {
      try {
        const response = await fetch('https://eunitstest.onrender.com/api/getconnectiondetails');
        if (response.ok) {
          const data = await response.json();
          const formattedData = data.map(Connection => ({
            Connection: Connection.Connection || 'None',
            Connection_ID: Connection.id || null
          }));
          setConnections([{ connection: 'None', Connection_ID: null }, ...formattedData]);
        } else {
          console.error('Failed to fetch connections. HTTP status:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchConnections();
  }, []);

  useEffect(() => {
  const fetchSettings = async () => {
    try {
      const response = await fetch('https://eunitstest.onrender.com/api/getsettings');
      console.log(response);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSettings(data.map(setting => ({
          settingName: setting.SettingName,
          settingId: setting.setting_id
        })));
      } else {
        console.error('Failed to fetch settings. HTTP status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };
  fetchSettings();
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name === 'company') {
      const selectedCompany = companies.find(company => company.company === value);
      console.log(selectedCompany);
  
      setFormData(prevData => ({
        ...prevData,
        company: value,
        company_ID: selectedCompany ? selectedCompany.company_ID: null,
        unitId: deviceData?.unitId || prevData.unitId
      }));
  
      if (selectedCompany && selectedCompany.company_ID) {
        try {
          const response = await axios.post('https://eunitstest.onrender.com/api/getcompanysettings', {
            company_ID: selectedCompany.company_ID,
          });
  
          if (response.status === 200) {
            const responseData = response.data;
  
            const settingsData = responseData.map(s => ({
              settingName: s.SettingName,
              settingId: s.setting_id
            }));
            setSettings(settingsData);
          } else {
            console.error('Failed to fetch settings. HTTP status:', response.status);
            setSettings([{ settingId: null, settingName: 'Default Settings' }]);
          }
        } catch (error) {
          console.error('Error fetching company settings:', error);
          setSettings([{ settingId: null, settingName: 'Default Settings' }]);
        }
      } else {
        setSettings([{ settingId: null, settingName: 'Default Settings' }]);
      }
    }  else if (name === 'setting') {
      const selectedSetting = settings.find(s => s.settingId.toString() === value);
  
      setFormData(prevData => ({
        ...prevData,
        settingId: selectedSetting ? selectedSetting.settingId : '',
        SettingName: selectedSetting ? selectedSetting.settingName : '',
        description: !editMode && selectedSetting ? selectedSetting.settingName : prevData.description,
        unitId: deviceData?.unitId || prevData.unitId
      }));
    } else if (name === 'Connection') {
      const selectedConnection = Connections.find(conn => conn.Connection === value);
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        connection_ID: selectedConnection ? selectedConnection.connection_ID : '',
        unitId: formData.company && selectedConnection ? deviceData?.unitId : '',
        status:'notdone'
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        unitId: deviceData ? deviceData.unitId : prevData.unitId,
        status:'notdone'
      }));
    }
  };
  
  useEffect(() => {
    if (editMode && deviceData) {
      setFormData((prevDetails) => ({
        ...prevDetails,
        macAddress: deviceData.macAddress || '',
        hardwareDetails: deviceData.hardwareDetails || '',
        category: deviceData.category || '',
        description: deviceData.description || '',
        entryway: deviceData.entryway || '',
        company: deviceData.company || '',
        venue: deviceData.venue || '',
        Connection:deviceData.Connection || '',
        settingId: deviceData.settingId || '',
        SettingName: deviceData.settingName || '',
      }));
    }
  }, [editMode, deviceData]);

  const handleControlUnit = async () => {
    try {
      console.log('Before API call');
      const dataToSend = {
        unitId: formData.unitId,
        macAddress: formData.macAddress,
        hardwareDetails: formData.hardwareDetails,
        category: formData.category,
        description: formData.description,
        entryway: formData.entryway,
        venue: formData.venue,
        company: formData.company,
        Connection: formData.Connection,
        company_ID: formData.company_ID,
        settingId: formData.settingId,
        SettingName: formData.SettingName, 
        status: "notdone"
      };
  
      const response = await axios.post('https://eunitstest.onrender.com/api/adddevice', dataToSend);
      console.log('API response:', response.data);
      setRegistrationStatus('success');
  
      const newDeviceData = response.data.result;
      console.log(newDeviceData);
      onConnectionUpdated(newDeviceData);
    } catch (error) {
      console.error('Error posting data to API:', error.response.data);
      setRegistrationStatus('failure');
    }
  };
  
  const handleUpdateDevice = async () => {
    try {
      const updatedData = {
        unitId: formData.unitId,
        macAddress: formData.macAddress,
        hardwareDetails: formData.hardwareDetails,
        category: formData.category,
        description: formData.description,
        entryway: formData.entryway,
        venue: formData.venue,
        company: formData.company,
        Connection:formData.Connection,
        company_ID: formData.company_ID,
        connection_ID:formData.connection_ID,
        settingId: formData.settingId,
        SettingName: formData.SettingName,
        status:"notdone"
      };
  
      const response = await axios.post(`https://eunitstest.onrender.com/api/updatedevice`, updatedData);

      if (response.status === 200) {
        console.log('Device updated successfully:', response.data);
        updateTableData(updatedData);
        setUpdateStatus('success');
      } else {
        console.error('Error updating device:', response.data.error || 'Unknown error occurred.');
      }
    } catch (error) {
      console.error('Error updating device:', error);
    }
  };
  
  const handleSave = async (e) => {
    e.preventDefault();

    try {
      if (!validateForm()) {
        console.error('Validation failed.');
        return;
      }

      if (editMode) {
        await handleUpdateDevice();
      } else {
        await handleControlUnit();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex md:items-center items-start pt-14 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto">
      <div 
        className="max-w-sm md:max-w-xl p-6 pt-5"
        style={{
        background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
          borderBottom: '1px solid black'
        }}
      >
        <div className="flex flex-row gap-2 items-start justify-between pb-10">
          <div className='flex flex-col'>
             <h2 className="font-semibold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>{editMode ? 'Edit device' : 'New device'}</h2>
            <span className='text-gray-600'>{editMode ? 'Edit Devices below' : 'Please add Device information below'}</span>
          </div>

          <img
              onClick={closeModal}
              src={close}
              alt="User"
              className="cursor-pointer mt-4"
            />
        </div>

        {registrationStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 rounded mb-4">
            Registration successful!
          </div>
        )}

        {updateStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 rounded mb-4">
            Update successful!
          </div>
        )}

        {updateStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 rounded mb-4">
            Update failed. Please try again.
          </div>
        )}
 
        <form>
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full md:w-1/2 px-3 mb-2">
              <label htmlFor="macAddress" className=" text-gray-600">
                Mac Address <span className='text-red-500'>*</span>
              </label>
              <input
                type="text"
                id="macAddress"
                name="macAddress"
                placeholder='None'
                value={formData.macAddress}
                onChange={handleChange}
                disabled={editMode}
                className={`mt-1 py-1.5 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none ${formErrors.macAddress ? 'border-red-500' : '' }`}
                />
                {
                  formErrors.macAddress && (
                    <p className='text-red-500 text-xs italic'> {formErrors.macAddress} </p>
                )}
            </div>

            <div className="w-full md:w-1/2 px-3 mb-2">
              <label htmlFor="" className=" text-gray-600">
                Category <span className='text-red-500'>*</span>
              </label>
              <select
                 id="category"
                 name="category"
                 placeholder='None'
                 value={formData.category}
                 onChange={handleChange}
                 className={`mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none ${formErrors.category ? 'border-red-500' : '' }`}
                 rows="3"
                 required
              >
                {categories.map((category) => (
                  <option key={category} value={category}>
                  {category}
                </option>
                ))}
              </select>
              {
                formErrors.category && (
                  <p className='text-red-500 text-xs italic'>{formErrors.category}</p>
                )
              }
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
              <label htmlFor="description" className=" text-gray-600">
                Name <span className='text-red-500'>*</span>
              </label>
              <input
                id="description"
                name="description"
                placeholder='None'
                value={formData.description} 
                onChange={handleChange}
                className={`${formErrors.description ? 'border-red-500' : ''} mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none`}
                rows="3"
                required
              />

              {
                formErrors.description && (
                  <p className='text-red-500 text-xs italic'>{formErrors.description}</p>
                )
              }
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full md:w-1/2 px-3 mb-2">
              <label htmlFor="location" className=" text-gray-600">
                Venue <span className='text-red-500'>*</span>
              </label>
              <input
                type="text"
                id="venue"
                name="venue"
                placeholder='None'
                value={formData.venue}
                onChange={handleChange}
                className={`${formErrors.venue ? 'border-red-500' : ''} mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none`}
                required
              />
              { formErrors.venue && (
                <p className='text-red-500 text-xs italic'>{formErrors.venue}</p>
              )}
            </div>

            <div className="w-full md:w-1/2 px-3">
              <label htmlFor="area" className=" text-gray-600">
                Entryway <span className='text-red-500'>*</span>
              </label>
              <input
                type="text"
                id="entryway"
                name="entryway"
                placeholder='None'
                value={formData.entryway}
                onChange={handleChange}
                className={`${formErrors.entryway ? 'border-red-500' : '' } mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none`}
                required
              />
              {
                formErrors.entryway && (
                  <p className='text-red-500 text-xs italic'>{formErrors.entryway}</p>
                )
              }
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-2">
            <div className={`w-full ${editMode ? 'md:w-full' : 'md:w-1/2'} mb-2 px-3`}>
              <label htmlFor="role" className=" text-gray-600 mb-2" style={{fontSize:'14px', lineHeight:'20px', letterSpacing:'0%'}}>
                Company <span className='text-red-500'>*</span>
              </label>
              <select
                id="company"
                name="company"
                placeholder='None'
                value={formData.company}
                onChange={handleChange}
                className={`${formErrors.company ? 'border-red-500' : ''} mt-1 w-full px-2 py-1.5 text-gray-600 border focus:outline-none`}
              >
                {companies.map((company) => (
                  <option key={company.company} value={company.company}>
                    {company.company}
                  </option>
                ))}
              </select>
              {
                formErrors.company && (
                  <p className='text-red-500 text-xs italic'>{formErrors.company}</p>
                )
              }
            </div>

            {!editMode && (
              <div className="w-full md:w-1/2 px-3 mb-2">
                <label htmlFor="setting" className="text-gray-600 mb-2" style={{ fontSize: '14px', lineHeight: '20px', letterSpacing: '0%' }}>
                  Initial Settings <span className="text-red-500">*</span>
                </label>
                <select
                  id="setting"
                  name="setting"
                  value={formData.settingId || ''} 
                  onChange={handleChange}
                  className={`mt-1 w-full px-2 py-1.5 text-gray-600 border focus:outline-none ${formErrors.setting ? 'border-red-500' : ''}`}
                >
                  {settings.map((setting) => (
                    <option key={setting.settingId} value={setting.settingId}>
                      {setting.settingName}
                    </option>
                  ))}
                </select>
                {formErrors.setting && (
                  <p className="text-red-500 text-xs italic">{formErrors.setting}</p>
                )}
              </div>
            )}

          </div>

          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-3 mb-2">
              <label htmlFor="hardwareDetails" className=" text-gray-600">
                Hardware info <span className='text-red-500'>*</span>
              </label>
              <input
                id="hardwareDetails"
                name="hardwareDetails"
                placeholder='None'
                value={formData.hardwareDetails}
                onChange={handleChange}
                className={`${formErrors.hardwareDetails ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
              />
                {formErrors.hardwareDetails && (
                  <p className="text-red-500 text-xs italic">{formErrors.hardwareDetails}</p>
                )}
            </div>
          </div>

          <div className="flex md:flex-row md:justify-end flex-col gap-2 mt-6">
            <button
              onClick={closeModal}
              className="mr-2 px-3 py-2 text-gray-600  border focus:outline-none focus:ring focus:border-gray-300"
              style={{ color: '#344054'}}
            >
              Discard
            </button>
            <button
              onClick={handleSave}
              type="submit"
              className={`py-2 px-3 bg-black text-white hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300`}
            >
              {editMode ? 'Save changes' : 'Register unit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EControlUnitForm;
