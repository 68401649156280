import React, { useState, useEffect } from 'react';
import axios from 'axios';
import close from '../Assets/close.svg';
import icelandIcon from '../Assets/is.svg';
import franceIcon from '../Assets/fr.svg';
import ukIcon from '../Assets/uk.svg';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './styles.css';
import CustomDropdown from './CustomDropDown';
import vector from '../Assets/dropdown.svg';

const DeviceSettingsForm = ({ device, onClose, updateDeviceData }) => {

const [TTS_Language, setTTS_Language] = useState('is');
const [switchStates, setSwitchStates] = useState(false);
const [switchStatesButton, setSwitchStatesButton] = useState(false);
const [switchStatesAccess, setSwitchStatesAccess] = useState(false);
const [disabledFromTime, setDisabledFromTime] = useState(null);
const [disabledToTime, setDisabledToTime] = useState(null);
const [officeOpenTime, setOfficeOpenTime] = useState(new Date());
const [officeCloseTime, setOfficeCloseTime] = useState(new Date());
const [updateStatus, setUpdateStatus] = useState(null);
const [gradientHeight, setGradientHeight] = useState('');

  useEffect(() => {
    const updateGradientHeight = () => {
      const height = window.innerWidth < 640 ? '4.7%' : '6%';
      setGradientHeight(height);
    };
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, []);

    const localeOptions = [
        { value: 'is', label: 'is - Icelandic', icon: icelandIcon },
        { value: 'us', label: 'us - English', icon: ukIcon },
        { value: 'fr', label: 'fr - French', icon: franceIcon },
    ];

    useEffect(() => {
        setSwitchStates(device.TTS_ON === 'on');
    }, [device.TTS_ON]);

    useEffect(() => {
        setSwitchStatesAccess(device.access_allowed === 'yes');
    }, [device.access_allowed]);

    useEffect(() => {
        setSwitchStatesButton(device.open_button_disabled === 'enabled');
    }, [device.open_button_disabled]);

    const handleToggleSlider = async (value) => {
        setSwitchStates(value);
    };

    const handleToggleSliderButton = async (value) => {
        setSwitchStatesButton(value);
    };

    const handleToggleSliderAccess = async (value) => {
        setSwitchStatesAccess(value);
    };

    const getSliderStyle = (volume) => {
        return {
            background: '#349999',
            opacity: volume / 10
        };
    };

    const [settings, setSettings] = useState({
        macaddress: device.macAddress || '',
        unitId: device.unitId || '',
        pre_booking_grace_minutes:device.pre_booking_grace_minutes || '',
        keyborad_beep: device.keyborad_beep ||'',
        access_allowed: device.access_allowed ||'',
        entry_welcome:device.entry_welcome ||'',
        entry_welcome_delay: '0',
        code_error: device.code_error ||'code is incorrect',
        code_expired:device.code_expired ||'code expired',
        code_soon_active:device.code_soon_active ||'code access soon',
        goodbye_message: device.goodbye_message ||'see you soon',
        code_digits_length: device.code_digits_length ||'6',
        lock_open_seconds: device.lock_open_seconds ||'',
        open_button_disabled: device.open_button_disabled ||'',
        open_button_disabled_from_time: device.open_button_disabled_from_time ||'',
        open_button_disabled_to_time: device.open_button_disabled_to_time ||'',
        sound_volume: device.sound_volume ||'',
        lock_relay_default_state: device.lock_relay_default_state ||'',
        lock_relay_gpio_number: device.lock_relay_gpio_number ||'',
        TTS_Language: device.TTS_Language ||'',
        office_open_minute: device.office_open_minute ||'',
        office_closure_minute: device.office_closure_minute ||'',
        office_closed_message: device.office_closed_message ||'we are currently closed we serve from strt to end',
        TTS_ON: device.TTS_ON ||'',
        lock_pump_default_state: device.lock_pump_default_state ||'',
        lock_pump_gpio_number: device.lock_pump_gpio_number ||'',
        lock_pump_open_seconds: device.lock_pump_open_seconds ||'',
        open_button_macaddress: device.open_button_macaddress ||'',
        Device_ip: device.Device_ip || ''
    });

    const [volume, setVolume] = useState(settings.sound_volume);

    useEffect(() => {
        setVolume(device.sound_volume || '50');
    }, [device.sound_volume]);    

    useEffect(() => {
        setTTS_Language(device.TTS_Language || 'is');
    }, [device.TTS_Language ]);
  
    const handleChange = (event) => {
        const { name, value } = event.target;
        setSettings(prevSettings => ({ ...prevSettings, [name]: value }));
        if (name === 'sound_volume') {
            setVolume(Number(value));
        }
    };

    useEffect(() => {
        const parseDateTime = (dateTimeStr) => {
            if (!dateTimeStr) return null;
            const year = parseInt(dateTimeStr.substring(0, 4), 10);
            const month = parseInt(dateTimeStr.substring(4, 6), 10) - 1;
            const day = parseInt(dateTimeStr.substring(6, 8), 10);
            const hour = parseInt(dateTimeStr.substring(8, 10), 10);
            const minute = parseInt(dateTimeStr.substring(10, 12), 10);
            return new Date(year, month, day, hour, minute);
        };

        setDisabledFromTime(parseDateTime(device.open_button_disabled_from_time));
        setDisabledToTime(parseDateTime(device.open_button_disabled_to_time));
    }, [device.open_button_disabled_from_time, device.open_button_disabled_to_time]);

    const handleDateTimeChange = (name, date) => {
        const formattedDateTime = `${date.getFullYear()}${('0' + (date.getMonth() + 1)).slice(-2)}${('0' + date.getDate()).slice(-2)}${('0' + date.getHours()).slice(-2)}${('0' + date.getMinutes()).slice(-2)}`;
        setSettings(prev => ({ ...prev, [name]: formattedDateTime }));
        if (name === 'open_button_disabled_from_time') {
            setDisabledFromTime(date);
        } else if (name === 'open_button_disabled_to_time') {
            setDisabledToTime(date);
        }
    };

    useEffect(() => {
        if (device.office_open_minute) {
            const [hours, minutes] = device.office_open_minute.split(':');
            const date = new Date();
            date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
            setOfficeOpenTime(date);
        }
        if (device.office_closure_minute) {
            const [hours, minutes] = device.office_closure_minute.split(':');
            const date = new Date();
            date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
            setOfficeCloseTime(date);
        }
    }, [device.office_open_minute, device.office_closure_minute]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const updatedSettings = {
            ...settings,
            TTS_ON: switchStates ? 'on' : 'off',
            access_allowed: switchStatesAccess ? 'yes' : 'no',
            open_button_disabled: switchStatesButton ? 'enabled' : 'disabled',
            TTS_Language: TTS_Language,
            sound_volume: volume
        };

        try {
            const response = await axios.post('https://eunitstest.onrender.com/api/devicesetting', updatedSettings);
            console.log('Settings updated:', response.data);
            updateDeviceData({ ...device, ...updatedSettings });
            setUpdateStatus('success');
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    };
    
    return (
        <div className="fixed inset-0 flex items-start pt-6 pb-6 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto">
            <div 
            className="max-w-sm md:max-w-xl p-6 pt-5"
            style={{
                background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
                borderBottom: '1px solid black'
            }}
            >
                <div className="flex flex-row gap-2 items-start justify-between pb-10">
                    <div className='flex flex-col'>
                        <h2 className="font-semibold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Device Settings</h2>
                        <span className='text-gray-600'>{device.description}, settings</span>
                    </div>

                    <img
                        onClick={onClose}
                        src={close}
                        alt="User"
                        className="cursor-pointer mt-4"
                    />
                </div>

                {updateStatus === 'success' && (
                    <div className="bg-green-200 text-green-800 p-2 rounded mb-4">
                        Update successful!
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="macAddress" className=" text-gray-900">
                            MAC address
                            </label>
                            <input
                                type="text"
                                id="macAddress"
                                name="macAddress"
                                placeholder='None'
                                disabled
                                value={settings.macaddress}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            />
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="macAddress" className=" text-gray-900">
                                Unit ID
                            </label>
                            <input
                                type="text"
                                id="macAddress"
                                name="unitId"
                                placeholder='None'
                                disabled
                                value={settings.unitId}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="macAddress" className="text-gray-900">
                                Pre Booking Minutes
                            </label>
                            <input
                                type="text"
                                id="pre_booking_grace_minutes"
                                name="pre_booking_grace_minutes"
                                placeholder='None'
                                value={settings.pre_booking_grace_minutes}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            />
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="hardwareDetails" className=" text-gray-900">
                            Keyboard Beep
                            </label>
                            <select
                                id="keyborad_beep"
                                name="keyborad_beep"
                                placeholder='None'
                                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                                value={settings.keyborad_beep}
                                onChange={handleChange}
                            >
                                <option value="0">0</option>
                                <option value="1">1</option>
                            </select>
                        </div>

                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="hardwareDetails" className=" text-gray-900">
                                Access Allowed
                            </label>
                            <div className='flex flex-row gap-4 items-center'>
                                <div className='flex flex-row gap-2 items-center'>
                                    <div className="custom-switch-bar1" onClick={() => handleToggleSliderAccess(true)} style={{
                                        backgroundColor: switchStatesAccess ? '#010808' : '#c4c4c4',
                                    }}>
                                    <span className="custom-switch-circle" style={{
                                            transform: switchStatesAccess ? 'translateX(1.25rem)' : 'translateX(0)',
                                        }}></span>
                                    </div>
                                    <span className='text-black mt-2' style={{
                                        '@media (max-width: 600px)': {
                                            fontSize: '14px' 
                                        }
                                    }} onClick={() => handleToggleSliderAccess(true)}>Yes</span>
                                </div>
                                <div className='flex flex-row gap-2 items-center'>
                                    <div className="custom-switch-bar1" onClick={() => handleToggleSliderAccess(false)} style={{
                                        backgroundColor: !switchStatesAccess ? '#010808' : '#c4c4c4',
                                    }}>
                                        <span className="custom-switch-circle" style={{
                                            transform: !switchStatesAccess ? 'translateX(1.25rem)' : 'translateX(0)',
                                        }}></span>
                                    </div>
                                    <span className='text-black mt-2' style={{
                                        '@media (max-width: 600px)': {
                                            fontSize: '14px'
                                        }
                                    }} onClick={() => handleToggleSliderAccess(false)}>No</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="macAddress" className=" text-gray-900">
                                Welcome Delay
                            </label>
                            <select
                                id="keyborad_beep"
                                name="keyborad_beep"
                                placeholder='None'
                                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                                value={settings.entry_welcome_delay}
                                onChange={handleChange}
                            >
                                <option value="0">0</option>
                            </select>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="hardwareDetails" className=" text-gray-900">
                                Code Digits Length
                            </label>
                            <input
                                id="code_digits_length"
                                name="code_digits_length"
                                placeholder='None'
                                value={settings.code_digits_length}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                                rows="3"
                                required
                            ></input>
                        </div>

                        <div className="w-full md:w-1/2 px-3">
                        <label htmlFor="sound_volume" className="text-gray-900">
                            Sound Volume
                        </label>
                        <div className="flex flex-col items-center justify-center">
                            <div className="text-gray-900">
                                {volume}%
                            </div>
                            <input
                                type="range"
                                id="sound_volume"
                                name="sound_volume"
                                min="0"
                                max="100"
                                value={volume}
                                onChange={handleChange}
                                style={getSliderStyle(volume)}
                                className="px-2 w-full border text-gray-600 focus:outline-none"
                            />
                        </div>
                    </div>
                    </div>

                    <hr className="my-4" />
                    <div className="text-center text-gray-900 font-semibold mb-3">Door/Pump Control Settings</div>
                    <hr className="my-4" />

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3">
                            <label htmlFor="area" className=" text-gray-900">
                                Lock Relay Default State
                            </label>
                            <select
                                type="text"
                                id="lock_relay_default_state"
                                name="lock_relay_default_state"
                                placeholder='None'
                                value={settings.lock_relay_default_state}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            >
                                <option value="0">0</option>
                                <option value="1">1</option>
                            </select>
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="location" className=" text-gray-900">
                                Lock Pump Default State
                            </label>
                            <select
                                type="text"
                                id="lock_pump_default_state"
                                name="lock_pump_default_state"
                                placeholder='None'
                                value={settings.lock_pump_default_state}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            > 
                                <option value="0">0</option>
                                <option value="1">1</option>
                            </select>
                        </div>        
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="location" className=" text-gray-900">
                            Lock Open Seconds
                            </label>
                            <select
                                type="text"
                                id="lock_open_seconds"
                                name="lock_open_seconds"
                                placeholder='None'
                                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                                value={settings.lock_open_seconds}
                                onChange={handleChange}
                            >
                                <option value="0">10</option>
                            </select>
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="location" className=" text-gray-900">
                            Lock Pump Open Seconds
                            </label>
                            <select
                                type="text"
                                id="lock_pump_open_seconds"
                                name="lock_pump_open_seconds"
                                placeholder='None'
                                value={settings.lock_pump_open_seconds}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            >
                                {[...Array(20).keys()].map((second) => (
                                    <option key={second + 1} value={second + 1}>
                                    {second + 1}
                                    </option>
                                ))}
                            </select>
                        </div>          
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="location" className=" text-gray-900">
                                Lock Relay GPIO Number
                            </label>
                            <select
                                type="text"
                                id="lock_relay_gpio_number"
                                name="lock_relay_gpio_number"
                                placeholder='None'
                                value={settings.lock_relay_gpio_number}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            >
                                <option value="4">4</option>
                                <option value="20">20</option>
                                <option value="22">22</option>
                                <option value="26">26</option>
                            </select>
                        </div>

                        <div className="w-full md:w-1/2 px-3">
                            <label htmlFor="area" className=" text-gray-900">
                                Lock Pump GPIO Number
                            </label>
                            <select
                                type="text"
                                id="lock_pump_gpio_number"
                                name="lock_pump_gpio_number"
                                placeholder='None'
                                value={settings.lock_pump_gpio_number}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            >
                                <option value="4">4</option>
                                <option value="20">20</option>
                                <option value="22">22</option>
                                <option value="26">26</option>
                            </select>
                        </div>   
                    </div>

                    <hr className="my-4" />
                    <div className="text-center text-gray-900 font-semibold mb-3">Open Button Settings</div>
                    <hr className="my-4" />

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="location" className="text-gray-900">Open Button</label>
                            <div className='flex flex-row gap-4 items-center'>
                                <div className='flex flex-row gap-2 items-center'>
                                    <div className="custom-switch-bar1" onClick={() => handleToggleSliderButton(true)} style={{
                                        backgroundColor: switchStatesButton ? '#010808' : '#c4c4c4',
                                    }}>
                                        <span className="custom-switch-circle" style={{
                                            transform: switchStatesButton ? 'translateX(1.25rem)' : 'translateX(0)',
                                        }}></span>
                                    </div>
                                    <span className='text-black mt-2' onClick={() => handleToggleSliderButton(true)}>Enabled</span>
                                </div>
                                <div className='flex flex-row gap-2 items-center'>
                                    <div className="custom-switch-bar1" onClick={() => handleToggleSliderButton(false)} style={{
                                        backgroundColor: !switchStatesButton ? '#010808' : '#c4c4c4',
                                    }}>
                                        <span className="custom-switch-circle" style={{
                                            transform: !switchStatesButton ? 'translateX(1.25rem)' : 'translateX(0)',
                                        }}></span>
                                    </div>
                                    <span className='text-black mt-2'onClick={() => handleToggleSliderButton(false)}>Disabled</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="area" className=" text-gray-900">
                                Open Disabled From Time
                            </label>
                            <DatePicker
                                selected={disabledFromTime}
                                onChange={(date) => handleDateTimeChange('open_button_disabled_from_time', date)}
                                showTimeSelect
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="Time"
                                className="mt-1 pr-48 md:pr-14 pl-2  py-1.5 w-full border text-gray-600 focus:outline-none"
                            />
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="location" className=" text-gray-600">
                                Open Disabled to Time
                            </label>
                            <DatePicker
                                selected={disabledToTime}
                                onChange={(date) => handleDateTimeChange('open_button_disabled_to_time', date)}
                                showTimeSelect
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="Time"
                                className="mt-1 pr-48 md:pr-14 pl-2  py-1.5 w-full border text-gray-600 focus:outline-none"
                            />
                        </div>
                    </div>     

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3">
                            <label htmlFor="area" className=" text-gray-900">
                                Open Button MAC address
                            </label>
                            <input
                                type="text"
                                id="open_button_macaddress"
                                name="open_button_macaddress"
                                placeholder='None'
                                value={settings.open_button_macaddress}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            />
                        </div>

                        <div className="w-full  md:w-1/2 px-3 mb-2">
                            <label htmlFor="location" className=" text-gray-900">
                                Device IP
                            </label>
                            <input
                                type="text"
                                id="Device_ip"
                                name="Device_ip"
                                placeholder='None'
                                value={settings.Device_ip}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            />
                        </div> 
                    </div>

                    <hr className="my-4" />
                    <div className="text-center text-gray-900 font-semibold mb-3">Office Working Hours Settings</div>
                    <hr className="my-4" />

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3">
                            <label htmlFor="area" className=" text-gray-900">
                                Office Open Minute
                            </label>
                            <input
                                type="time"
                                id="office_open_minute"
                                name="office_open_minute"
                                placeholder='None'
                                value={settings.office_open_minute}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            />
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="location" className=" text-gray-900">
                                Office Closure Minute
                            </label>
                            <input 
                                type="time"
                                id="office_closure_minute"
                                name="office_closure_minute"
                                placeholder='None'
                                value={settings.office_closure_minute}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            />
                        </div>
                    </div> 

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full px-3">
                            <label htmlFor="area" className=" text-gray-900">
                                Office Closed Message
                            </label>
                            <textarea
                                type="text"
                                id="office_closed_message"
                                name="office_closed_message"
                                placeholder='None'
                                value={settings.office_closed_message}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            />
                        </div>
                    </div>

                    <hr className="my-4" />
                    <div className="text-center text-gray-900 font-semibold mb-3">TTS and Language Settings</div>
                    <hr className="my-4" />

                    <div className="flex flex-wrap mb-2">
                        <div className="w-full md:w-1/2 mb-2 md:mb-4">
                            <label htmlFor="smsActivity" className="text-gray-900">
                                TTS Enabled/Disabled
                            </label>
                        <div className='flex flex-row gap-4 items-center'>

                        <div className='flex flex-row gap-2 items-center'>
                                <div className="custom-switch-bar1" onClick={() => handleToggleSlider(true)} style={{
                                    backgroundColor: switchStates ? '#010808' : '#c4c4c4',
                                }}>
                                        <span className="custom-switch-circle" style={{
                                            transform: switchStates ? 'translateX(1.25rem)' : 'translateX(0)',
                                        }}></span>
                                </div>
                                <span className='text-black mt-2' onClick={() => handleToggleSlider(true)}>Enabled</span>
                            </div>

                            <div className='flex flex-row gap-2 items-center'>
                                <div className="custom-switch-bar1" onClick={() => handleToggleSlider(false)} style={{
                                    backgroundColor: !switchStates ? '#010808' : '#c4c4c4',
                                }}>
                                <span className="custom-switch-circle" style={{
                                        transform: !switchStates ? 'translateX(1.25rem)' : 'translateX(0)',
                                    }}></span>
                                    
                                </div>
                                <span className='text-black mt-2' onClick={() => handleToggleSlider(false)}>Disabled</span>
                            </div>
                        </div>
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="location" className="block text-gray-900 mb-1">
                                TTS Language
                            </label>
                            <div className="relative">
                                <CustomDropdown 
                                    options={localeOptions} 
                                    value={TTS_Language} 
                                    onChange={setTTS_Language}
                                    className="appearance-none w-full"
                                />
                                <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
                                    <img src={vector} alt="Dropdown Arrow" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="hardwareDetails" className=" text-gray-900">
                                Entry Welcome
                            </label>
                            <input
                                id="entry_welcome"
                                name="entry_welcome"
                                placeholder='None'
                                value={settings.entry_welcome}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                                rows="3"
                                required
                            ></input>
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="hardwareDetails" className=" text-gray-900">
                                Code Error
                            </label>
                            <input
                                id="code_error"
                                name="code_error"
                                placeholder='None'
                                value={settings.code_error}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                                rows="3"
                                required
                            ></input>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="hardwareDetails" className=" text-gray-900">
                                Code Expired
                            </label>
                            <input
                                id="code_expired"
                                name="code_expired"
                                placeholder='None'
                                value={settings.code_expired}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                                rows="3"
                                required
                            ></input>
                        </div>

                        <div className="w-full md:w-1/2 px-3 mb-2">
                            <label htmlFor="hardwareDetails" className=" text-gray-900">
                                Code Soon Active
                            </label>
                            <input
                                id="code_soon_active"
                                name="code_soon_active"
                                placeholder='None'
                                value={settings.code_soon_active}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                                rows="3"
                                required
                            ></input>
                        </div>

                    </div>

                    <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full px-3">
                            <label htmlFor="area" className=" text-gray-900">
                            Goodbye Message
                            </label>
                            <textarea
                                type="text"
                                id="goodbye_message"
                                name="goodbye_message"
                                placeholder='None'
                                value={settings.goodbye_message}
                                onChange={handleChange}
                                className="mt-1 px-2 py-1.5 w-full border text-gray-600 focus:outline-none"
                                required
                            />
                        </div>
                    </div>

                    <div className="flex md:flex-row md:justify-end flex-col gap-2 mt-6">
                        <button
                            onClick={onClose}
                            className="mr-2 px-3 py-2 text-gray-600  border focus:outline-none"
                            style={{ color: '#344054', border: '1px solid #ccc', backgroundColor: 'transparent'}}
                        >
                        Discard
                        </button>
                        {switchStatesButton ? (
                            <button 
                                type="submit" 
                                className='py-2 px-3 bg-black text-white hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300'
                            >
                            Save changes
                            </button>
                            ) : (
                            <button 
                                onClick={handleSubmit} 
                                type="button" className='py-2 px-3 bg-black text-white hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300'
                            >
                            Save changes
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    
    );
};

export default DeviceSettingsForm;
