import React, { useState, useEffect } from 'react';
import { Link, useHistory  } from 'react-router-dom/cjs/react-router-dom.min';
import { BrowserRouter as Redirect } from 'react-router-dom';
import { FiMenu, FiX, FiUsers} from 'react-icons/fi';
import photos from '../Assets/Entro.svg';
import avator2 from '../Assets/avator3.png';
import bookings from '../Assets/Booking.svg';
import action from '../Assets/ActionN.svg';
import connect from '../Assets/Connection.svg';
import device from '../Assets/Device.svg';
import event from '../Assets/eventf.svg';
import home from '../Assets/Dashboard.svg';
import HomeM from '../Assets/HomeM.svg';
import help from '../Assets/Help.svg';
import company from '../Assets/Company.svg';
import logout from '../Assets/logout.svg';
import sms from '../Assets/sms.svg';

const Sidebar = () => {
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [userData, setUserData] = useState(null);
  const storedRole = localStorage.getItem('role');
  const isAuthenticated = localStorage.getItem('userId');

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []); 

  useEffect(() => {
    if (!isAuthenticated || storedRole !== 'System Admin') {
      history.push('/login');
    }
  }, [isAuthenticated,storedRole, history]);

  useEffect(() => {
    if (!isAuthenticated || storedRole !== 'Company User') {
      history.push('/login');
    }
  }, [isAuthenticated,storedRole, history]);
  
  const handleLogout = () => {
    handleTabChange('Logout');
    localStorage.removeItem('userId');
    localStorage.removeItem('userData');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('company');
    localStorage.removeItem('sessionId');
    setTimeout(() => {
      window.location.href = '/login';
    }, 500);
  };
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    console.log(userData)
  }; 

  const getDynamicStyle = (isActive) => ({
    backgroundColor: isActive ? '#349999' : 'transparent',
    justifyContent: isMenuOpen ? 'initial' : 'center',
    transition: 'background-color 0.3s ease, justify-content 0.3s ease',
  });

  const hoverStyle = {
    backgroundColor: '#1a202c',
  };

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={` text-white items-center h-full justify-center w-1/9 md:w-1/7 pt-6 p-5 transition-all ease-in-out duration-300`} style={{backgroundColor:'#010808'}}>
      <div className="cursor-pointer flex items-center justify-between px-4 py-2 mb-4" onClick={toggleMenu}>
        {isMenuOpen ? (
          <FiX className="ml-44" size={30} />
        ) : (
          <FiMenu  size={26} />
        )}
      </div>

      <Link to="/dashboard" className="flex items-center  gap-3 px-4 py-2 text-white" onClick={() => handleTabChange('Dashboard')}>
        <div
          className={`text-white text-3xl rectangle-full object-cover ${isMenuOpen ? 'hidden' : 'block'}`}
          style={{ justifyContent: isMenuOpen ? 'initial' : 'initial' }}
        >
          <img src={photos} alt="User" />
        </div>
        <div
          className={`flex rectangle-full object-cover ${isMenuOpen ? 'block' : 'hidden'}`}
          style={{ justifyContent: isMenuOpen ? 'initial' : 'initial' }}
        >
          <img src={HomeM} alt="User" />
        </div>
      </Link>

      <div className={` ${isMenuOpen ? 'mt-4 block' : 'block'}`} >
          <div className={` ${isMenuOpen ? 'mt-4 block' : 'block'}`} >
                  {storedRole === 'System Admin' && (
                    <div className='flex flex-col justify-between'>
                      <div className='flex-grow'>
                        <Link 
                          className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-2 text-white"
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Dashboard' ? '#349999' : 'transparent'}
                          style={getDynamicStyle(activeTab === 'Dashboard')}
                          to='/dashboard'
                          title="Go to Dashboard"
                          onClick={() => handleTabChange('Dashboard')}
                        > 
                          <img
                            src={home}
                            alt="User"
                            className=""
                          />
                          <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Dashboard</span>
                        </Link>
                        <Link
                          to="/booking"
                          title="Go to Booking"
                          onClick={() => handleTabChange('Booking')}
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Booking' ? '#349999' : 'transparent'}
                          style={getDynamicStyle(activeTab === 'Booking')}
                          className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white transition-colors duration-300"
                        >
                          <img
                            src={bookings}
                            alt="Booking Icon"
                            className="rectangle-full object-cover"
                          />
                          <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Bookings</span>
                        </Link>           
                        <Link 
                          className="flex items-center justify-center font-semibold gap-3 px-4 pl-2 py-2 mb-2 text-white"
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'SMSLog' ? '#349999' : 'transparent'}
                          style={getDynamicStyle(activeTab === 'SMSLog')}
                          to="/sms" 
                          title="View SMS Log"
                          onClick={() => handleTabChange('SMSLog')}
                        >
                          <img
                            src={sms}
                            alt="User"
                            className="w-8 h-8"
                          />
                          <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>View SMS Log</span>
                        </Link>
                        <Link 
                          className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Events' ? '#349999' : 'transparent'}
                          style={getDynamicStyle(activeTab === 'Events')}
                          to="/events" 
                          title="Go to Events"
                          onClick={() => handleTabChange('Events')}
                        >
                          <img
                            src={event}
                            alt="User"
                            className="rectangle-full object-cover"
                          />
                          <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Events</span>
                        </Link>
                        <Link 
                          className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Action' ? '#349999' : 'transparent'}
                          style={getDynamicStyle(activeTab === 'Action')}
                          to="/actions" 
                          title="Go to action"
                          onClick={() => handleTabChange('Action')}
                          >
                          <img
                            src={action}
                            alt="User"
                            className="rectangle-full object-cover"
                          />
                          <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Actions</span>
                        </Link>
                        <Link 
                          className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Connections' ? '#349999' : 'transparent'}
                          style={getDynamicStyle(activeTab === 'Connections')}
                          to="/connections" 
                          title="Go to Connections"
                          onClick = {() => handleTabChange('Connections')}
                        >
                          <img
                            src={connect}
                            alt="User"
                            className="rectangle-full object-cover"
                          />
                          <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Connections</span>
                        </Link>
                        <Link 
                          className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Devices' ? '#349999' : 'transparent'}
                          style={getDynamicStyle(activeTab === 'Devices')}
                          to="/devices" 
                          title="Go to Devices"
                          onClick ={() => handleTabChange('Devices')}
                        >
                          <img
                            src={device}
                            alt="User"
                            className="rectangle-full object-cover"
                          />
                          <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Devices</span>
                        </Link>
                        <Link 
                          className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Companies' ? '#349999' : 'transparent'}
                          style={getDynamicStyle(activeTab === 'Companies')}
                          to="/companies" 
                          title="Go to Companies"
                          onClick ={() => handleTabChange('Companies')}
                        >
                          <img
                            src={company}
                            alt="User"
                            className="rectangle-full object-cover"
                          />
                          <span className={`${isMenuOpen ? 'block' : 'hidden'}`}>Companies</span>
                        </Link>
                        <Link 
                          className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 text-white"
                          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Users' ? '#349999' : 'transparent'}
                          style={getDynamicStyle(activeTab === 'Users')}
                          to="/users" 
                          title="Go to New Users"
                          onClick = {() => handleTabChange('Users')}
                        >
                          <FiUsers className=" text-white" size={24} />
                          <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Users</span>
                        </Link>
                      </div>
                        <div className= {`w-full border-b-2 mt-12 pt-4 border-gray-700 ${isMenuOpen ? 'block' : 'block'}`} ></div>
                        <div className="">
                          <Link 
                            className={`flex items-center font-semibold justify-center gap-3 px-4 pl-3 py-4 mb-2 text-white`}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Logout' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Logout')} 
                            to="/login" 
                            title="Go to Logout"
                            onClick={handleLogout}
                          >
                            <img
                              src={logout}
                              alt="User"
                              className=""
                            />  
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`}>Logout</span>
                          </Link>
                  
                          <Link 
                            className={`flex items-center font-semibold justify-center gap-3 px-4 pl-3 py-4 mb-2 text-white`}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Help' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Help')}
                            to="/help" 
                            title="Go to Help"
                            onClick={() => handleTabChange('Help')}
                          >
                            <img
                              src={help}
                              alt="User"
                              className=""
                            /> 
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px', letterSpacing:'0%'}}>Help</span>
                          </Link>
      
                          <Link
                            className="flex items-center justify-center gap-3 py-3 px-1 mb-3 text-white"
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Profile' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Profile')}  
                            to="/profile"
                            title="Go to Profile"
                            onClick = {()=> handleTabChange('Profile')}
                          >
                            {userData && (
                            <img
                              src={userData.image || avator2}
                              className='w-10 h-10'
                              alt={`Profile of ${userData.firstName}`}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = avator2; 
                              }} 
                            />
                          )}
                            <div className={`flex flex-col items-center ${isMenuOpen ? 'block' : 'hidden'}`}>
                              {userData && (
                                <>
                                  <span>{userData.firstName} {userData.lastName}</span>
                                  <Link>{userData.email}</Link>
                                </>
                              )}
                            </div>
                          </Link>
                      </div>
                </div>
                  )}

                  {(storedRole === 'Company User' || storedRole === 'Company Admin')  && (
                      <div className='flex flex-col h-full '>
                        <div className='flex-grow'>
                          <Link 
                            className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-2 text-white"
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Dashboard' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Dashboard')}
                            to='/dashboard'
                            title="Go to Dashboard"
                            onClick={() => handleTabChange('Dashboard')}
                          > 
                            <img
                              src={home}
                              alt="User"
                              className=""
                            />
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Dashboard</span>
                          </Link>
                          <Link
                            to="/booking"
                            title="Go to Booking"
                            onClick={() => handleTabChange('Booking')}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Booking' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Booking')}
                            className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white transition-colors duration-300"
                          >
                            <img
                              src={bookings}
                              alt="Booking Icon"
                              className="rectangle-full object-cover"
                            />
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Bookings</span>
                          </Link>
                          <Link 
                            className="flex items-center justify-center font-semibold gap-3 px-4 pl-4 py-2 mb-2 text-white"
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'SMSLog' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'SMSLog')}
                            to="/sms" 
                            title="View SMS Log"
                            onClick={() => handleTabChange('SMSLog')}
                          >
                            <img
                              src={sms}
                              alt="User"
                              className="w-8 h-8"
                            />
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>View SMS Log</span>
                          </Link>
                          <Link 
                            className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Events' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Events')}
                            to="/events" 
                            title="Go to Events"
                            onClick={() => handleTabChange('Events')}
                          >
                            <img
                              src={event}
                              alt="User"
                              className="rectangle-full object-cover"
                            />
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Events</span>
                          </Link>
                          <Link 
                            className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Action' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Action')}
                            to="/actions" 
                            title="Go to action"
                            onClick={() => handleTabChange('Action')}
                            >
                            <img
                              src={action}
                              alt="User"
                              className="rectangle-full object-cover"
                            />
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Actions</span>
                          </Link>
                          <Link 
                            className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Devices' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Devices')}
                            to="/devices" 
                            title="Go to Devices"
                            onClick ={() => handleTabChange('Devices')}
                          >
                            <img
                              src={device}
                              alt="User"
                              className="rectangle-full object-cover"
                            />
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Devices</span>
                          </Link>
                          {storedRole === ('Company Admin') && (
                            <Link 
                              className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-3 text-white"
                              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Companies' ? '#349999' : 'transparent'}
                              style={getDynamicStyle(activeTab === 'Companies')}
                              to="/companies" 
                              title="Go to Companies"
                              onClick ={() => handleTabChange('Companies')}
                            >
                              <img
                                src={company}
                                alt="User"
                                className="rectangle-full object-cover"
                              />
                              <span className={`${isMenuOpen ? 'block' : 'hidden'}`}>Companies</span>
                            </Link>
                          )}
                          <Link 
                            className="flex items-center justify-center font-semibold gap-3 px-4 pl-3 py-4 mb-12 text-white"
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Users' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Users')}
                            to="/users" 
                            title="Go to New Users"
                            onClick = {() => handleTabChange('Users')}
                          >
                            <FiUsers className=" text-white" size={24} />
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px'}}>Users</span>
                          </Link>
                        </div>
                        <div className= {`w-full border-b-2 mt-12 pt-4 border-gray-700 ${isMenuOpen ? 'hidden' : 'block'}`} ></div>
                        <div className="">
                          <Link 
                            className={`flex items-center font-semibold justify-center gap-3 px-4 pl-3 py-4 mb-2 text-white`}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Logout' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Logout')} 
                            to="/login" 
                            title="Go to Logout"
                            onClick={handleLogout}
                          >
                            <img
                              src={logout}
                              alt="User"
                              className=""
                            />  
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`}>Logout</span>
                          </Link>
                          <Link 
                            className={`flex items-center font-semibold justify-center gap-3 px-4 pl-3 py-4 mb-2 text-white`}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Help' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Help')}
                            to="/help" 
                            title="Go to Help"
                            onClick={() => handleTabChange('Help')}
                          >
                            <img
                              src={help}
                              alt="User"
                              className=""
                            /> 
                            <span className={`${isMenuOpen ? 'block' : 'hidden'}`} style={{lineHeight:'24px', fontSize:'16px', letterSpacing:'0%'}}>Help</span>
                          </Link>
                          <div className= {`w-full border-b-2 mt-12 border-gray-700 ${isMenuOpen ? 'block' : 'hidden'}`} ></div>
                          <Link
                            className="flex items-center justify-center gap-3 py-3 px-1 mb-3 text-white"
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = hoverStyle.backgroundColor}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = activeTab === 'Profile' ? '#349999' : 'transparent'}
                            style={getDynamicStyle(activeTab === 'Profile')}  
                            to="/profile"
                            title="Go to Profile"
                            onClick = {()=> handleTabChange('Profile')}
                          >
                            {userData && (
                              <img
                                src={userData.image || avator2}
                                className='w-10 h-10'
                                alt={`Profile of ${userData.firstName}`}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = avator2; 
                                }} 
                              />
                            )}
                            <div className={`flex flex-col items-center ${isMenuOpen ? 'block' : 'hidden'}`}>
                              {userData && (
                                <>
                                  <span>{userData.firstName} {userData.lastName}</span>
                                  <Link>{userData.email}</Link>
                                </>
                              )}
                            </div>
                          </Link>
                        </div>
                    </div>
                  )}
          </div>
      </div>
    </div>
  );
}

export default Sidebar;


